<template>
    <div>
        <Tabs v-model="activeTab" ref="header" @on-click="initData">
          <TabPane :name="$options.isReadMap.UNREAD.key" label="未读消息" />
          <TabPane :name="$options.isReadMap.READED.key" label="已读消息" />
        </Tabs>

        <!--表格-->
        <Table row-key="id" :tooltip-theme="$store.state.theme" :height="tableHeight" :loading="loading"
               :columns="tableColumns" :data="tableData" stripe border></Table>

        <!-- 分页 -->
        <Page ref="footer" :total="total" :current="currentPage" show-total show-sizer @on-change="handleCurrentChange"
              @on-page-size-change="handlePageSize" style="float: right;"/>
    </div>
</template>

<script>
    import tableHeightMixin from '_m/tableHeight.mixin'
    import tablePageMixin from '_m/tablePage.mixin'
    import { timeFormat } from '@/utils'

    // 消息分类
    const mTypeMap = {
      0: '外部公告',
      1: '内部公告',
      2: '告警',
      3: '待办',
      4: '业务',
      5: '其他'
    }

    // 发送类型
    const sTypeMap = {
      0: '单发',
      1: '广播'
    }

    const isReadMap = {
      UNREAD: { key: '0', value: '未读' },
      READED: { key: '1', value: '已读' }
    }
    export default {
      name: 'MessageComp',
      mixins: [tableHeightMixin, tablePageMixin],
      isReadMap,
      data() {
          return {
              activeTab: isReadMap.UNREAD.key,
              tableData: [],
          }
      },
      created() {
          this.initData()
      },
      computed: {
            tableColumns () {
                let columns = []
                columns.push({
                    title: '标题',
                    minWidth: 200,
                    key: 'title',
                    align: 'center',
                }, {
                    title: '消息类型',
                    width: 96,
                    align: 'center',
                    render: (h, params) => {
                        const { row } = params
                        const text = mTypeMap[row.mtype]
                        return h('span', text)
                    }
                }, {
                    title: '发送类型',
                    width: 96,
                    align: 'center',
                    render: (h, params) => {
                        const { row } = params
                        const text = sTypeMap[row.stype]
                        return h('span', text)
                    }
                }, {
                    title: '内容',
                    minWidth: 200,
                    key: 'content',
                    align: 'center',
                }, {
                    title: '创建时间',
                    width: 180,
                    key: 'createTime',
                    align: 'center',
                    render: (h, params) => {
                        const { row } = params
                        const text = timeFormat(row.createTime)
                        return h('span', undefined, text)
                    }
                })
                columns.push({
                    title: '操作',
                    align: 'center',
                    width: 180,
                    render: (h, params) => {
                        const array = []
                        const { row } = params

                        if (row.isRead === Number(isReadMap.UNREAD.key)) {
                          array.push(h('Button', {
                              style: {
                                  marginRight: '5px'
                              },
                              props: {
                                  icon: 'ios-eye-outline',
                                  type: 'success',
                                  size: 'small'
                              },
                              on: {
                                  click: () => {
                                      this.handleRead(row)
                                  }
                              }
                          }, '详情'))
                        }

                        array.push(h('Button', {
                            props: {
                                icon: 'ios-trash',
                                type: 'error',
                                size: 'small'
                            },
                            on: {
                                click: () => {
                                    this.handleDeleteMessage(row)
                                }
                            }
                        }, '删除'))
                        return h('div', array)
                    }
                })
                return columns
            }
        },
      methods: {
        initData () {
            console.log(this.activeTab)
            this.loading = true
            this.$http.get(`/portal/v1/biz-station-msg/m/findByUserId`, {
              params: {
                  page: this.currentPage,
                  pageCount: this.pageSize,
                  isRead: this.activeTab
              }
            }).then((res) => {
                let { code, msg, data } = res
                if (code === 1) {
                    this.tableData = data.records
                    this.total = data.total
                } else {
                    this.$Notice.error({
                        title: `接口报错:code=${code}`,
                        desc: msg
                    })
                }
            }).finally(() => {
                this.loading = false
            })
        },
        handleRead(item) {
            console.log(item)
            if (item.btype === 2) {
                this.$router.push({
                    path: '/withdrawal',
                    query: {
                        id: item.bid
                    }
                })
            }
            if (item.btype === 3 || item.btype === 6) {
                this.$router.push({
                    path: '/recharge',
                    query: {
                        id: item.bid,
                        type: item.btype
                    }
                })
            }
            this.$http.post(`/portal/v1/biz-station-msg/m/updRead?msgId=${item.id}&isRead=${Number(isReadMap.READED.key)}`)
                .then((res) => {
                    const { code, msg } = res

                    if (code === 1) {
                        this.initData()
                    } else {
                        this.$Notice.error({
                            title: `接口报错:code=${code}`,
                            desc: msg
                        })
                    }
            })
        },
        handleDeleteMessage (item) {
            this.$Modal.confirm({
                title: '删除确认',
                content: `<p>确认删除消息-${item.title}?</p>`,
                onOk: () => {
                    this.$http.get(`/portal/v1/biz-station-msg/m/del?id=${item.id}`).then((res) => {
                        const { code, msg } = res
                        if (code === 1) {
                            this.initData()
                        } else {
                            this.$Notice.error({
                                title: `接口报错:code=${code}`,
                                desc: msg
                            })
                        }
                    })
                }
            })
        }
      }
    }
    </script>
